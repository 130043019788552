import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import {
  Header,
  SidebarMobileDrawer,
  SidebarToggle,
  UserNameWithAvatar,
  SidebarButton,
  StyledPopover,
  StyledPopoverOption,
} from './components';
import { ChevronUp, ChevronDown, SignOut } from 'assets/icons';
import { useEffect, useState } from 'react';
import { useScreenWidth } from 'hooks';
import { useAuthContext } from 'contexts';

export const QuizLayout = () => {
  const { signOut } = useAuthContext();

  const { isSm } = useScreenWidth();

  const [isDesktopUserPopoverOpened, setIsDesktopUserPopoverOpened] =
    useState<HTMLElement | null>(null);
  const [isMobileSidebarOpened, setMobileSidebarOpened] = useState(false);

  const onMobileSidebarClose = () => {
    setMobileSidebarOpened(false);
  };

  useEffect(() => {
    if (!isSm && isMobileSidebarOpened) onMobileSidebarClose();
    if (isSm && isDesktopUserPopoverOpened) setIsDesktopUserPopoverOpened(null);
  }, [isSm, isMobileSidebarOpened, isDesktopUserPopoverOpened]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Header
        rightSlot={
          isSm ? (
            <SidebarToggle
              isOpen={isMobileSidebarOpened}
              toggle={() => setMobileSidebarOpened(!isMobileSidebarOpened)}
            />
          ) : (
            <Box
              onClick={(e) => setIsDesktopUserPopoverOpened(e.currentTarget)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                svg: {
                  marginLeft: 8,
                  path: { stroke: 'white' },
                },
              }}
            >
              <UserNameWithAvatar />
              {isDesktopUserPopoverOpened ? <ChevronUp /> : <ChevronDown />}
            </Box>
          )
        }
      />

      <StyledPopover
        open={!!isDesktopUserPopoverOpened}
        anchorEl={isDesktopUserPopoverOpened}
        onClose={() => setIsDesktopUserPopoverOpened(null)}
      >
        <StyledPopoverOption
          color="error"
          onClick={() => {
            setIsDesktopUserPopoverOpened(null);
            signOut();
          }}
        >
          Logout
        </StyledPopoverOption>
      </StyledPopover>

      <SidebarMobileDrawer
        fullHeight={false}
        isMobileSidebarOpened={isMobileSidebarOpened && isSm}
        onClose={onMobileSidebarClose}
      >
        <SidebarButton
          onClick={() => {
            onMobileSidebarClose();
            signOut();
          }}
          icon={<SignOut />}
          noBottomSeparator
          noSpacingBottom
          noSpacingTop
        >
          Logout
        </SidebarButton>
      </SidebarMobileDrawer>

      <Box
        sx={(theme) => ({
          my: 'auto',
          py: '30px',
          overflow: 'auto',
        })}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
