import {
  grayTextColor,
  grey200,
  primaryMain,
  regularTextColor,
  secondaryLight,
  secondaryMain,
  white,
} from './palette';

import type { Components } from '@mui/material';

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: `
          * {
            box-sizing: border-box;
          }
          html {
            font-size: 62.5%;
          }
          body {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            width: 100%;
          }
          html, body, #root {
            height: 100%;
          }
          a {
            text-decoration: none;
            color: inherit;
          }
        `,
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '100px',
        textTransform: 'none',
        boxShadow: 'none',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '26px',
        minWidth: 'unset',
        '&:hover, &:active': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        backgroundColor: primaryMain,
        color: white,
      },
      containedSecondary: {
        backgroundColor: secondaryLight,
        color: regularTextColor,

        '&:hover': {
          backgroundColor: secondaryMain,
        },
      },
      textPrimary: {
        color: regularTextColor,
      },
      sizeMedium: {
        padding: '16px 32px',
      },
      sizeSmall: {
        padding: '12px 32px',
      },
      // outlined is not configured since we don't have outlined buttons
      outlined: {
        color: primaryMain,
        borderColor: primaryMain,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      disabled: {},
      root: {
        borderRadius: '6px',
        '&.Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.03) !important',
        },
      },
      input: {
        padding: '16px',
        fontSize: '16px',
        lineHeight: '18px',
        height: 'unset',
      },
      notchedOutline: {
        borderColor: grey200,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        top: '100%',
        position: 'absolute',
        marginLeft: 0,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: '-9px',
        userSelect: 'none',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      endAdornment: {
        '&.MuiAutocomplete-endAdornment.MuiAutocomplete-endAdornment.MuiAutocomplete-endAdornment':
          {
            right: '12px',
          },
      },
      popper: {
        '.MuiAutocomplete-paper': {
          boxShadow: '0px 10px 30px -6px rgba(40, 40, 40, 0.09)',
          borderRadius: '6px',
        },

        '.MuiAutocomplete-listbox .MuiAutocomplete-option': {
          marginBottom: '8px',
          borderRadius: '6px',
          fontSize: '15px',
          color: grayTextColor,
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        overscrollBehaviorY: 'contain',
        boxSizing: 'border-box',
        // need to research how to use shadows in contexts of MUI instead of hard-code
        boxShadow: '0px 12px 40px -6px rgba(48, 78, 118, 0.15)',
        border: 'none',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: '0px 2px 10px -1px rgba(48, 78, 118, 0.08)',
        borderRadius: 10,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        ':before': {
          display: 'none',
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '23px',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
};
