import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

import { paths } from 'routing';
import { useUserProfileContext } from 'contexts';
import { useMemo } from 'react';
import { checkUserProfileCompleteness } from './checkUserProfileCompleteness';

export const CompleteProfilePrompt = () => {
  const { userProfile } = useUserProfileContext();

  const completedInPercents = useMemo(
    () => checkUserProfileCompleteness(userProfile),
    [userProfile]
  );

  if (completedInPercents.raw === 100) return null;

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '27px 24px',
        backgroundColor: 'info.main',
        borderRadius: '16px',
        [theme.breakpoints.down('lg')]: { flexDirection: 'column' },
      })}
    >
      <Typography variant="textSmall">
        Good job. Complete your account setup now
        <br />({completedInPercents.formatted} complete)
      </Typography>
      <NavLink to={paths.myProfile()}>
        <Button
          variant="contained"
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: { marginTop: 12 },
          })}
        >
          Complete Your Profile
        </Button>
      </NavLink>
    </Box>
  );
};
