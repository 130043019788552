import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { currencies } from 'api';
import { ChevronDown } from 'assets/icons';
import { FieldBlockWrapper } from 'components';

type ExpectedSalaryInputProps = {
  expectedSalaryAmount: number | null;
  setExpectedSalaryAmount: (newValue: number | null) => void;
  expectedSalaryCurrencyCode: string | null;
  setExpectedSalaryCurrencyCode: (newValue: string | null) => void;
};

export const ExpectedSalaryInput = ({
  expectedSalaryAmount,
  setExpectedSalaryAmount,
  expectedSalaryCurrencyCode,
  setExpectedSalaryCurrencyCode,
}: ExpectedSalaryInputProps) => {
  return (
    <FieldBlockWrapper title="Expected annual salary for the new position">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          sx={{
            maxWidth: '70%',
            mr: 4,
          }}
          placeholder="E.g. 100000"
          value={expectedSalaryAmount ?? ''}
          onChange={({ target: { value } }) => {
            const castedToNumber = Number(value);
            if (value === '') return setExpectedSalaryAmount(null);
            if (isNaN(castedToNumber)) return;

            setExpectedSalaryAmount(castedToNumber);
          }}
        />

        <Autocomplete
          sx={{ flex: 1, minWidth: '120px' }}
          value={expectedSalaryCurrencyCode ?? null}
          onChange={(event, newValue) => {
            setExpectedSalaryCurrencyCode(newValue);
          }}
          options={currencies}
          popupIcon={<ChevronDown />}
          disableClearable={!!expectedSalaryCurrencyCode}
          renderInput={(params) => (
            <TextField {...params} placeholder="E.g. USD" />
          )}
        />
      </Box>
    </FieldBlockWrapper>
  );
};
