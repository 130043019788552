import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useUserProfileContext } from 'contexts';
import { CompleteProfilePrompt } from 'components';
import { ContentCard } from './ContentCard';
import { ContentSection } from './ContentSection';
import { resources, courses, events } from 'api';
import { useEffect } from 'react';
import { amplitudeService } from 'services';

export const Home = () => {
  const { userEmail, userNameCleanedUp } = useUserProfileContext();

  useEffect(() => {
    !!userEmail && amplitudeService.logDashboardVisited(userEmail);
  }, []);

  return (
    <>
      <Typography
        variant="h5"
        fontFamily="Caudex"
        sx={(theme) => ({
          marginBottom: 12,

          [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
          },
        })}
      >
        Hi {userNameCleanedUp} 👋,
      </Typography>
      <CompleteProfilePrompt />
      <Box
        sx={(theme) => ({
          marginTop: 25,

          [theme.breakpoints.down('sm')]: {
            marginTop: 20,
          },
        })}
      >
        <ContentSection title="Here are a few free resources to help you get started">
          {resources.map((resource) => (
            <ContentCard
              key={resource.href}
              href={resource.href}
              mediaType={resource.mediaType}
              mediaSrc={resource.mediaSrc}
              title={resource.title}
              description={resource.description}
            />
          ))}
        </ContentSection>
        <ContentSection title="Explore free courses">
          {courses.map((course) => (
            <ContentCard
              onClick={() => {
                userEmail &&
                  amplitudeService.logCourseClicked(userEmail, course.href);
              }}
              key={course.href}
              href={course.href}
              mediaType={course.mediaType}
              mediaSrc={course.mediaSrc}
              title={course.title}
              description={course.description}
            />
          ))}
        </ContentSection>
        <ContentSection title="Check out some of our upcoming events">
          {events.map((event) => (
            <ContentCard
              key={event.href}
              href={event.href}
              mediaType={event.mediaType}
              mediaSrc={event.mediaSrc}
              title={event.title}
              description={event.description}
            />
          ))}
        </ContentSection>
      </Box>
    </>
  );
};
