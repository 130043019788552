import { NavLink } from 'react-router-dom';
import { SidebarButton, SidebarButtonProps } from './SidebarButton';

interface SidebarLinkButton extends SidebarButtonProps {
  route: string;
  end?: boolean;
  noHighlightWhenActive?: boolean;
}

export const SidebarLinkButton = ({
  children,
  route,
  icon,
  end,
  noBottomSeparator,
  noSpacingTop,
  noSpacingBottom,
  onClick,
  noHighlightWhenActive = false,
}: SidebarLinkButton) => {
  return (
    <NavLink end={end} to={route}>
      {({ isActive }) => (
        <SidebarButton
          onClick={onClick}
          variant={isActive && !noHighlightWhenActive ? 'contained' : 'text'}
          color={isActive && !noHighlightWhenActive ? 'secondary' : 'primary'}
          icon={icon}
          noBottomSeparator={noBottomSeparator}
          noSpacingTop={noSpacingTop}
          noSpacingBottom={noSpacingBottom}
        >
          {children}
        </SidebarButton>
      )}
    </NavLink>
  );
};
