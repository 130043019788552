import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { StepProps } from '../types';
import { QuizStep } from '../components';
import CircularProgress from '@mui/material/CircularProgress';
import { FieldBlockWrapper } from 'components';
interface PickUsernameStepProps extends StepProps {
  onUserInputBlur?: () => void;
  userNameError: string;
  isUserUniqueCheckLoading?: boolean;
}

export const PickUsernameStep = ({
  value,
  setValue,
  onUserInputBlur,
  userNameError,
  isUserUniqueCheckLoading,
}: PickUsernameStepProps) => {
  return (
    <QuizStep title="Pick a username">
      <Box sx={{ width: '100%', display: 'flex' }}>
        <FieldBlockWrapper title="Your username">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              placeholder="e.g.: john_doe123 or john-doe123"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onUserInputBlur}
              error={!!userNameError && !!value}
              helperText={
                !!userNameError && (
                  <Typography variant="subtitle1">{userNameError}</Typography>
                )
              }
            />
            <CircularProgress
              size="28px"
              sx={{
                marginLeft: '12px',
                marginTop: '4px',
                visibility: isUserUniqueCheckLoading ? 'visible' : 'hidden',
              }}
            />
          </Box>
        </FieldBlockWrapper>
      </Box>
    </QuizStep>
  );
};
