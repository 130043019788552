import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CheckboxChecked, CheckboxIdle } from 'assets/icons';
import { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material';

interface CheckboxWithLabelProps {
  value: boolean;
  onChange: (newVal: boolean) => void;
  label: ReactNode;
  sx?: SxProps<Theme>;
}

export const CheckboxWithLabel = ({
  sx,
  value,
  onChange,
  label,
}: CheckboxWithLabelProps) => {
  return (
    <FormControlLabel
      sx={[{ display: 'flex', mb: '24px' }, ...(Array.isArray(sx) ? sx : [sx])]}
      control={
        <Checkbox
          checked={value}
          onChange={() => onChange(!value)}
          icon={<CheckboxIdle />}
          checkedIcon={<CheckboxChecked />}
        />
      }
      label={
        <Box>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            fontWeight={400}
          >
            {label}
          </Typography>
        </Box>
      }
    />
  );
};
