import { globalEmploymentAssistantDb } from './dbs';
import { mergeFieldsWithId } from './utils';

import type { TestimonialFields, TestimonialData } from './types';

class TestimonialsService {
  db = globalEmploymentAssistantDb;
  testimonialsTable = this.db<TestimonialFields>('tblKt5KVE5SsetH6g');

  async getTestimonials(): Promise<TestimonialData[]> {
    const response = await this.testimonialsTable.select().all();

    return mergeFieldsWithId(response);
  }
}

export const testimonialsService = new TestimonialsService();
