import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { LinkedIn } from 'assets/icons';
import { useAuthContext } from 'contexts';

export const LoginButton = ({ sx, ...rest }: ButtonProps) => {
  const { signInWithLinkedIn } = useAuthContext();

  return (
    <Button
      onClick={signInWithLinkedIn}
      variant="contained"
      sx={[
        {
          backgroundColor: (theme) => theme.palette.component.loginButton,
          padding: '12px 16px',
          borderRadius: '8px',
          svg: {
            flexShrink: 0,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <LinkedIn />
      <Typography ml={4} fontWeight={400} variant="subtitle1" color="grey.A100">
        Claim my GE-Pass with LinkedIn
      </Typography>
    </Button>
  );
};
