import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { db } from 'api';
import type { User } from '@supabase/supabase-js';

import { paths } from 'routing';
import { amplitudeService } from '../services';

type UserLoadingState = 'initial' | 'loading' | 'finished';

interface AuthContextValue {
  user: User | null;
  userLoadingState: UserLoadingState;
  signInWithLinkedIn: () => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  userLoadingState: 'initial',
  signInWithLinkedIn: () => {},
  signOut: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(() => null);
  const [userLoadingState, setUserLoadingState] =
    useState<UserLoadingState>('initial');

  useEffect(() => {
    setUserLoadingState('loading');

    db.auth.getUser().then(({ data, error }) => {
      // todo on error show notif
      setUser(data?.user ?? null);
      setUserLoadingState('finished');
    });

    db.auth.onAuthStateChange((state, session) => {
      setUser(session?.user ?? null);
      setUserLoadingState('finished');
    });

    // this is only for analytics purposes
    window.addEventListener('hashchange', async () => {
      const { data } = await db.auth.getUser();
      if (data) {
        const { user } = data;

        const userEmailFromSession = user?.email;
        userEmailFromSession && amplitudeService.logLogin(userEmailFromSession);

        const userCreatedAt = user?.created_at;
        const currentTime = new Date().getTime();
        const createdAtTime = userCreatedAt
          ? new Date(userCreatedAt).getTime()
          : 0;
        const differenceInMinutes = (currentTime - createdAtTime) / 1000 / 60;

        // means if user was created 1 minute ago we'll treat this as the first login after user creation
        differenceInMinutes < 1 &&
          userEmailFromSession &&
          amplitudeService.logAccountCreated(userEmailFromSession);
      }
    });
  }, []);

  const signInWithLinkedIn = async () => {
    const { error } = await db.auth.signInWithOAuth({
      provider: 'linkedin',

      options: {
        redirectTo: window.location.origin + paths.login(),
      },
    });

    // todo adjust type gen to v2
    // todo refactor types respecting release changes
    // https://supabase.com/docs/reference/javascript/release-notes
    console.log({ error });
  };

  const signOut = () => {
    db.auth.signOut();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userLoadingState,
        signInWithLinkedIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
