import { ReactNode } from 'react';
import Box from '@mui/material/Box';

type RowWrapperProps = {
  children: ReactNode;
};

export const RowWrapper = ({ children }: RowWrapperProps) => {
  return (
    <Box
      sx={{
        maxWidth: 'max-content',
        borderRadius: '1000px',
        backgroundColor: 'secondary.light',
      }}
    >
      {children}
    </Box>
  );
};
