import type { SVGProps } from 'react';

export const NavigationArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="26"
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.66659 13H28.3333M28.3333 13L16.6666 24.6666M28.3333 13L16.6666 1.33331"
      stroke="#1B1B1A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NavigationArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="26"
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'rotate(180deg)' }}
    {...props}
  >
    <path
      d="M1.66659 13H28.3333M28.3333 13L16.6666 24.6666M28.3333 13L16.6666 1.33331"
      stroke="#1B1B1A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
