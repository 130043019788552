import { UpdateMyUserProfileParams } from './types';
import { db } from '../client';
import { definitions } from '../types/supabase';

export const updateUserProfile = (
  updateParams: UpdateMyUserProfileParams,
  currentUserId: string
) => {
  return db
    .from('employee_user_profiles')
    .update(updateParams)
    .match({ user_id: currentUserId })
    .select<string, definitions['employee_user_profiles']>('*')
    .single();
};
