import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { remote_work_experience } from 'api';
import { QuizStep, StyledFormControlLabel } from '../components';
import { CheckboxIdle, CheckboxChecked } from 'assets/icons';
import { StepProps } from '../types';

export const RemoteWorkExperienceStep = ({ value, setValue }: StepProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <QuizStep title="How long have you been working remotely?">
        <FormControl sx={{ width: '80%' }}>
          <RadioGroup value={value} onChange={(e) => setValue(e.target.value)}>
            {remote_work_experience.map((option: string) => (
              <StyledFormControlLabel
                key={option}
                control={
                  <Radio
                    icon={<CheckboxIdle />}
                    checkedIcon={<CheckboxChecked />}
                  />
                }
                label={option}
                value={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </QuizStep>
    </Box>
  );
};
