import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useUserProfileContext } from 'contexts';

export const UserNameWithAvatar = ({
  dark,
  onClick,
}: {
  dark?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const { userProfile, userNameCleanedUp } = useUserProfileContext();

  const avatarUrl = userProfile?.user_profile_img_url ?? '';
  const fullName = userNameCleanedUp ?? '';

  return (
    <Box display="flex" alignItems="center" onClick={onClick}>
      {avatarUrl ? (
        <Box
          component="img"
          src={avatarUrl}
          alt="avatar"
          sx={{ width: '44px', height: '44px', borderRadius: '100%', mr: 6 }}
        />
      ) : (
        <Box
          sx={{
            width: '44px',
            height: '44px',
            backgroundColor: 'grey.A100',
            borderRadius: '100%',
            mr: 6,
          }}
        />
      )}
      <Typography
        variant="caption"
        fontWeight={400}
        color={dark ? 'text.regular' : 'grey.A100'}
      >
        {fullName || 'N/A'}
      </Typography>
    </Box>
  );
};
