export interface Course {
  href: string;
  mediaType: 'video' | 'audio' | 'picture' | 'iframe' | 'img';
  mediaSrc: string;
  title: string;
  description: string;
  duration: string;
  format: string;
  idealFor: string[];
}

export const courses: Course[] = [
  {
    href: 'https://www.oysterhr.com/distributed-work?utm_source=notion&utm_medium=hub&utm_campaign=b2e_gepass',
    mediaType: 'img',
    mediaSrc:
      'https://assets-global.website-files.com/5ffc74fef1579006dc588807/6196c8d33008a85790ea64b4_book.png',
    title: 'Preparing For Distributed Work',
    description:
      'Preparing for Distributed Work is your starting point for a remote-first career. Our foundational course will give you the tools you need to be successful in the distributed workforce. Designed so you can learn at your own pace, in just 3-5 hours you’ll be prepped and prepared for your next distributed role.',
    duration: '3-5 hours',
    format: 'Online self-paced course',
    idealFor: ['Job seekers', 'First remoters', 'Distributed enthusiasts'],
  },
  {
    href: 'https://www.oysterhr.com/job-applications-course?utm_source=notion&utm_medium=hub&utm_campaign=b2e_gepas',
    mediaType: 'img',
    mediaSrc:
      'https://assets-global.website-files.com/5ffc74fef1579006dc588807/6196c8d33008a85790ea64b4_book.png',
    title: 'Oyster Elevates: Job Applications',
    description:
      'Creating a great resume and cover letter can feel like a daunting task. In this short course, our Oyster experts will walk you through a range of strategies to optimize your approach, supporting you to develop a resume and cover letter that stands out from the crowd.',
    duration: '50 minutes',
    format: 'Online self-paced course',
    idealFor: ['Job seekers'],
  },
  {
    href: 'https://www.oysterhr.com/interview-skills-course?utm_source=notion&utm_medium=hub&utm_campaign=b2e_gepas',
    mediaType: 'img',
    mediaSrc:
      'https://assets-global.website-files.com/5ffc74fef1579006dc588807/6196c8d33008a85790ea64b4_book.png',
    title: 'Oyster Elevates: Interview Skills',
    description:
      'Even professionals with years of interview experience can feel nervous or overwhelmed before a job interview. In this short course, our Oyster experts will share their best advice for preparing for and acing your next interview.',
    duration: '60 minutes',
    format: 'Online self-paced course',
    idealFor: ['Job seekers'],
  },
];
