import { db } from '../client';
import { definitions } from '../types/supabase';
import { CreateMyUserProfileParams } from './types';

export const insertOnboardingUserProfile = (
  profileData: CreateMyUserProfileParams
) => {
  return db
    .from('employee_user_profiles')
    .insert(profileData)
    .select<string, definitions['employee_user_profiles']>('*')
    .single();
};
