import amplitude from 'amplitude-js';

import { isDevelopment, isProduction } from 'consts';

const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;

class AmplitudeService {
  constructor() {
    this.init();
  }

  client = amplitude.getInstance();
  isRegistered = false;

  init() {
    AMPLITUDE_KEY && amplitude.getInstance().init(AMPLITUDE_KEY);
    this.isRegistered = !!AMPLITUDE_KEY;
  }

  logEvent(
    event: string,
    data?: unknown,
    callback?: amplitude.Callback | undefined,
    errorCallback?: amplitude.Callback | undefined,
    outOfSession?: boolean | undefined
  ) {
    if (this.isRegistered && (isProduction || isDevelopment)) {
      this.client.logEvent(event, data, callback, errorCallback, outOfSession);
    }
  }

  // +
  logAccountCreated(user_email: string) {
    this.logEvent('gepass_account_created', {
      user_email,
    });
  }

  logProfileCompleted(user_email: string) {
    this.logEvent('gepass_profile_completed', {
      user_email,
    });
  }

  logLogin(user_email: string) {
    this.logEvent('gepass_account_login', {
      user_email,
    });
  }

  logOnboardingCompleted(user_email: string) {
    this.logEvent('gepass_onboarding_completed', {
      user_email,
    });
  }

  logProfileVisited(user_email: string) {
    this.logEvent('gepass_profile_visited', {
      user_email,
    });
  }

  logDashboardVisited(user_email: string) {
    this.logEvent('gepass_dashboard_visited', {
      user_email,
    });
  }

  logCoursesVisited(user_email: string) {
    this.logEvent('gepass_courses_visited', {
      user_email,
    });
  }

  logCourseClicked(user_email: string, course_link: string) {
    this.logEvent('gepass_courses_clicked', {
      user_email,
      course_link,
    });
  }
}

export const amplitudeService = new AmplitudeService();
