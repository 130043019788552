import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  border: '1px solid',
  borderColor: theme.palette.component.border,
  borderRadius: '8px',
  marginBottom: '8px',
  marginLeft: 0,
  marginRight: 0,
  padding: '16px 20px 16px 16px',
  '.MuiTypography-root': {
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 400,
  },
}));
