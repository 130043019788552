import Box from '@mui/material/Box';

import { ReactNode } from 'react';

export const SidebarWrapper = ({
  children,
  noBorders,
}: {
  children: ReactNode;
  noBorders?: boolean;
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        borderRight: '1px solid',
        borderColor: 'component.border',
        padding: '28px 16px',

        ...(noBorders && {
          borderWidth: 0,
        }),
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        {children}
      </Box>
    </Box>
  );
};
