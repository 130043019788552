import { ReactElement, ReactNode } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {
  SidebarOptionWrapper,
  SidebarOptionWrapperProps,
} from './SidebarOptionWrapper';

export interface SidebarButtonProps
  extends Omit<SidebarOptionWrapperProps, 'children'> {
  children: ReactNode;
  icon?: ReactElement;
  onClick?: () => void;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  disabled?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
}

export const SidebarButton = ({
  children,
  icon,
  noBottomSeparator,
  noSpacingTop,
  noSpacingBottom,
  variant,
  disabled,
  color,
  onClick,
}: SidebarButtonProps) => {
  return (
    <SidebarOptionWrapper
      noBottomSeparator={noBottomSeparator}
      noSpacingTop={noSpacingTop}
      noSpacingBottom={noSpacingBottom}
    >
      <Button
        onClick={onClick}
        fullWidth
        variant={variant}
        color={color}
        disabled={disabled}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '16px',

          path: {
            stroke: 'currentColor',
          },
        }}
      >
        {icon}
        {!!icon && <Box mr={5} />}
        {children}
      </Button>
    </SidebarOptionWrapper>
  );
};
