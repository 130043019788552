import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import type { ReactChild } from 'react';

export interface CarouselProps {
  children: ReactChild[];
  itemsToShow?: number;
}

const StyleOverrideWrapper = styled(Box)(({ theme }) => ({
  'div.carousel-slider': { overflow: 'visible' },
  '.carousel .thumbs-wrapper': {
    display: 'none',
  },
  '.carousel .slide': {
    padding: '0 10px',
  },
  '.control-dots': {
    top: '-56px',
    '.dot': {
      backgroundColor: theme.palette.component.carouselDot,
      boxShadow: 'none',
      '&.selected': {
        backgroundColor: theme.palette.component.selectedCarouselDot,
      },
    },
  },
}));

export const CustomCarousel = ({ children }: CarouselProps) => {
  return (
    <StyleOverrideWrapper>
      <Carousel
        key={children.length}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable
        autoPlay
        stopOnHover
        infiniteLoop
      >
        {children}
      </Carousel>
    </StyleOverrideWrapper>
  );
};
