import React, { ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { oysterHrDefaultTheme } from './theme';

export const ThemeWrapperWithCssReset: React.FC<{ children: ReactNode }> = ({
  children,
}) => (
  <ThemeProvider theme={oysterHrDefaultTheme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
