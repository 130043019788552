import { definitions } from 'api';

export const checkUserProfileCompleteness = (
  userProfile: definitions['employee_user_profiles'] | null
) => {
  if (!userProfile) return { formatted: '0%', raw: 0 };
  // role, seniority level, country, salary amount, salary currency
  const totalFieldsInMyProfile = 5;
  let filledCount = 0;

  const {
    user_job_title,
    seniority_level,
    user_country_code,
    expected_salary_amount,
    expected_salary_currency_code,
  } = userProfile;

  user_job_title && filledCount++;
  seniority_level && filledCount++;
  user_country_code && filledCount++;
  expected_salary_amount && filledCount++;
  expected_salary_currency_code && filledCount++;

  const costOfOneAnswer = 100 / totalFieldsInMyProfile;
  const percentageResult = costOfOneAnswer * filledCount;

  return { formatted: `${percentageResult}%`, raw: percentageResult };
};
