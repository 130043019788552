import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  definitions,
  getMyProfileById,
  insertOnboardingUserProfile,
  UpdateMyUserProfileParams,
  CreateMyUserProfileParamsWithoutUserId,
  updateUserProfile,
} from 'api';
import { useAuthContext } from './AuthContext';
import { checkUserProfileCompleteness } from '../components';
import { amplitudeService } from '../services';

type UserProfileLoadingState = 'initial' | 'loading' | 'finished';

type CreateMyUserProfileRequiredParams = Omit<
  CreateMyUserProfileParamsWithoutUserId,
  'user_profile_img_url' | 'user_email' | 'user_full_name'
>;

interface UserProfileContextValue {
  userProfile: definitions['employee_user_profiles'] | null;
  userProfileLoadingState: UserProfileLoadingState;
  userNameCleanedUp: string | null;
  userEmail: string | null;
  createMyUserProfile: (params: CreateMyUserProfileRequiredParams) => void;
  updateMyUserProfile: (params: UpdateMyUserProfileParams) => Promise<void>;
  deleteMyUserProfile: () => void;
}

const UserProfileContext = createContext<UserProfileContextValue>({
  userProfile: null,
  userProfileLoadingState: 'initial',
  userNameCleanedUp: null,
  userEmail: null,
  createMyUserProfile: () => {},
  updateMyUserProfile: async () => {},
  deleteMyUserProfile: () => {},
});

const cleanupTextFromEmojis = (text: string) => {
  if (!text) return null;

  return text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  );
};

export const useUserProfileContext = () => useContext(UserProfileContext);

export const UserProfileContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { user } = useAuthContext();
  const [userProfile, setUserProfile] = useState<
    definitions['employee_user_profiles'] | null
  >(null);
  const [userProfileLoadingState, setUserProfileLoadingState] = useState<
    'initial' | 'loading' | 'finished'
  >('initial');

  const createMyUserProfile = (
    initialProfileData: Omit<
      CreateMyUserProfileParamsWithoutUserId,
      'user_profile_img_url' | 'user_email' | 'user_full_name'
    >
  ) => {
    if (!user) return;

    const { id, user_metadata } = user;

    insertOnboardingUserProfile({
      ...initialProfileData,
      user_email: user_metadata.email,
      user_full_name: user_metadata.full_name,
      user_profile_img_url: user_metadata.picture,
      user_id: id,
    }).then(({ data, error }) => {
      if (data) {
        setUserProfile(data);
      }

      if (error) {
        setUserProfile(null);
      }
    });
  };

  const updateMyUserProfile = async (
    updateParams: UpdateMyUserProfileParams
  ) => {
    if (!user) return;

    const { data: updatedUserProfile } = await updateUserProfile(
      updateParams,
      user.id
    );

    if (updatedUserProfile) {
      const checkOld = checkUserProfileCompleteness(userProfile);
      const checkNew = checkUserProfileCompleteness(updatedUserProfile);

      checkOld.raw !== checkNew.raw &&
        checkNew.raw === 100 &&
        userProfile?.user_email &&
        amplitudeService.logProfileCompleted(userProfile.user_email);

      setUserProfile(updatedUserProfile);
    }
  };

  const deleteMyUserProfile = () => {};

  useEffect(() => {
    // we need to fetch user profile only if user is present
    if (user && !userProfile && userProfileLoadingState !== 'finished') {
      setUserProfileLoadingState('loading');

      getMyProfileById(user.id).then(({ data, error }) => {
        if (error) {
          // todo probably add error to state so that we can reflect it on UI (and auth context should do the same)
          setUserProfile(null);
        }
        if (data) {
          setUserProfile(data);
        }

        setUserProfileLoadingState('finished');
      });
    }
  }, [user, userProfile]);

  const userNameCleanedUp = cleanupTextFromEmojis(
    userProfile?.user_full_name ?? ''
  );

  const userEmail = userProfile?.user_email ?? null;

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        userProfileLoadingState,
        userNameCleanedUp,
        userEmail,
        createMyUserProfile,
        updateMyUserProfile,
        deleteMyUserProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
