import type { SVGProps } from 'react';

export const Home = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 9.5L12 4L21 9.5"
      stroke="#0E0D0D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8.5V19.0571C19 19.5779 18.7314 20 18.4 20H5.6C5.26863 20 5 19.5779 5 19.0571V8.5"
      stroke="#0E0D0D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
