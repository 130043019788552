import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { get_employee_user_profiles_count } from 'api';
import { FullHeightLoadingOverlay } from 'components';

export const PublicUser = ({
  redirectIfUserNotFound,
}: {
  redirectIfUserNotFound: string;
}) => {
  const params = useParams();
  const [publicUserProfile, setPublicUserProfile] = useState<unknown>(null);
  const [publicUserProfileLoadingState, setPublicUserProfileLoadingState] =
    useState<'initial' | 'loading' | 'finished'>('initial');

  useEffect(() => {
    setPublicUserProfileLoadingState('loading');
    get_employee_user_profiles_count().then((data) => {
      setPublicUserProfileLoadingState('finished');
    });
  }, []);

  if (publicUserProfileLoadingState !== 'finished') {
    return <FullHeightLoadingOverlay />;
  }

  // todo handle error + navigate away to redirectIfUserNotFound

  return <h1>PUBLIC USER PROFILE: {params.username}</h1>;
};
