import { createTheme } from '@mui/material/styles';

import { palette, grey900 } from './palette';
import { buildTypography } from './typography';
import { components } from './components';

export const oysterHrDefaultTheme = createTheme({
  palette,
  // theme.spacing(3) is equal to 3 * 2 = 6px
  spacing: 2,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: ['-apple-system', "'DM Sans'", "'Caudex'", 'sans-serif'].join(
      ','
    ),
    htmlFontSize: 10,
    allVariants: {
      fontSize: '18px',
      lineHeight: '26px',
      color: grey900,
    },
  },
});

buildTypography(oysterHrDefaultTheme);
