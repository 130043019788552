import Link from '@mui/material/Link';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ContentCardWrapper } from 'components';

export interface ContentCardProps {
  href: string;
  mediaType: 'video' | 'audio' | 'picture' | 'iframe' | 'img';
  mediaSrc: string;
  title: string;
  description: string;
  onClick?: () => void;
}

export const ContentCard = ({
  href,
  mediaType,
  mediaSrc,
  title,
  description,
  onClick,
}: ContentCardProps) => {
  return (
    <ContentCardWrapper>
      <Link
        onClick={onClick}
        href={href}
        underline="none"
        target="_blank"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardMedia
          sx={(theme) => ({
            height: '172px',

            [theme.breakpoints.down('sm')]: {
              height: '158px',
            },
          })}
          component={mediaType}
          src={mediaSrc}
        />
        <Box sx={{ width: '90%', marginTop: '8px' }}>
          <Typography
            variant="textSmall"
            fontWeight={700}
            mt={4}
            sx={{
              wordWrap: 'break-word',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight={400}
            sx={{
              wordWrap: 'break-word',
            }}
          >
            {description}
          </Typography>
        </Box>
      </Link>
    </ContentCardWrapper>
  );
};
