import { db } from '../client';
import { definitions } from '../types/supabase';

export const getMyProfileById = (currentUserId: string) => {
  return db
    .from('employee_user_profiles')
    .select<string, definitions['employee_user_profiles']>(
      `
    *,
    preferred_hiring_locations:hiring_location_options!employee_user_profiles_locations__hiring_location_options (
      id,
      location_code,
      location_label
    )
    `
    )
    .eq('user_id', currentUserId)
    .single();
};
