import { styled } from '@mui/system';
import { Button, Popover } from '@mui/material';

export const StyledPopover = styled(
  Popover,
  {}
)((theme) => ({
  '.MuiPaper-root': {
    padding: 0,
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.2)',
    minWidth: 'max-content',
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

StyledPopover.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const StyledPopoverOption = styled(Button)((theme) => ({
  fontSize: '16px',
  lineHeight: '24px',
  padding: '12px 16px',
  minWidth: '180px',
  justifyContent: 'flex-start',
  borderRadius: 0,
}));
