import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import { FieldBlockWrapper, ClearButton } from 'components';
import { ChevronDown } from 'assets/icons';

import type { SxProps } from '@mui/material';

export interface SelectOption {
  code: string;
  label: string;
}

interface CustomMultiselectAutocompleteProps {
  title: string;
  placeholder: string;
  limitTags?: number;
  options: SelectOption[];
  disabled?: boolean;
  value: SelectOption[];
  setValue: (value: SelectOption[]) => void;
  sx?: SxProps;
}

export const CustomMultiselectAutocomplete = ({
  title,
  placeholder,
  limitTags = 1,
  options,
  disabled,
  value,
  setValue,
  sx,
}: CustomMultiselectAutocompleteProps) => {
  const handleClear = () => setValue([]);
  return (
    <FieldBlockWrapper
      title={title}
      onClear={value.length ? handleClear : null}
    >
      <Autocomplete
        sx={[
          { '.MuiInputBase-root': { paddingY: '6px' } },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        multiple
        disableCloseOnSelect
        disableClearable
        limitTags={limitTags}
        options={options}
        value={value}
        disabled={disabled}
        getOptionLabel={(option) => option.label}
        popupIcon={<ChevronDown />}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={!!value.find((item) => item.code === option.code)}
            />
            {option.label}
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        onChange={(event, newValue) => setValue(newValue)}
      />
    </FieldBlockWrapper>
  );
};
