import type { SVGProps } from 'react';

export const Cancel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0004 12L17.243 17.2426M6.75781 17.2426L12.0004 12L6.75781 17.2426ZM17.243 6.75736L12.0004 12L17.243 6.75736ZM12.0004 12L6.75781 6.75736L12.0004 12Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
