import Box from '@mui/material/Box';
import persona1 from './persona1.png';
import persona2 from './persona2.png';
import persona3 from './persona3.png';
import persona4 from './persona4.png';
import persona5 from './persona5.png';
import { useEffect } from 'react';

export const SideIllustration = () => {
  useEffect(() => {
    const elements =
      document
        .getElementById('illustration-wrapper')
        ?.querySelectorAll('div') ?? [];

    elements.forEach((el) => {
      el.style.transition = '0.2s';
      el.style.opacity = '0';
      el.style.transform = 'scale(0.7,0.7)';
    });

    const shuffled = Array.from(elements)
      ?.map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    shuffled.forEach((el, i) =>
      setTimeout(() => {
        el.style.opacity = '1';
        el.style.transform = 'scale(1,1)';
      }, (i + 1) * 30)
    );
  }, []);

  return (
    <Box
      id="illustration-wrapper"
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        overflow: 'hidden',

        img: {
          transition: '0.2s',
          userSelect: 'none',
        },
        'img:hover': {
          transform: 'scale(1.03,1.03) !important',
        },
      }}
    >
      {/* first column start */}

      <Box
        sx={{
          right: '374px',
          position: 'absolute',
          top: '-47px',
          borderRadius: '1000px',
          width: '217px',
          height: '217px',
          background: (theme) => theme.palette.secondary.light,
        }}
      />

      <Box
        sx={{
          right: '375px',
          position: 'absolute',
          top: '190px',
          borderRadius: '40px',
          width: '216px',
          height: '218px',
          background: (theme) => theme.palette.component.lightBluePlaceholder,
        }}
      >
        <img src={persona1} draggable={false} alt="" />
      </Box>

      <Box
        sx={{
          right: '493px',
          position: 'absolute',
          top: '428px',
          width: '99px',
          height: '99px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      />

      <Box
        sx={{
          right: '493px',
          position: 'absolute',
          top: '547px',
          width: '99px',
          height: '289px',
          borderRadius: '40px',
          background: (theme) => theme.palette.component.lightBluePlaceholder,
        }}
      />

      <Box
        sx={{
          right: '493px',
          position: 'absolute',
          top: '856px',
          width: '98px',
          height: '218px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '1092px',
          right: '240px',
          width: '351px',
          height: '351px',
          borderRadius: '40px',
          background: (theme) => theme.palette.secondary.light,
        }}
      />

      <Box
        sx={{
          right: '374px',
          position: 'absolute',
          top: '1463px',
          borderRadius: '1000px',
          width: '217px',
          height: '217px',
          background: (theme) => theme.palette.component.lightBluePlaceholder,
        }}
      />

      <Box
        sx={{
          right: '-105px',
          position: 'absolute',
          top: '1700px',
          borderRadius: '40px',
          width: '696px',
          height: '116px',
          background: (theme) => theme.palette.info.light,
        }}
      />

      {/* first column end */}

      {/* second column start */}

      <Box
        sx={{
          position: 'absolute',
          top: '-119px',
          right: '138px',
          borderRadius: '40px',
          width: '216px',
          height: '218px',
          background: (theme) => theme.palette.component.lightBluePlaceholder,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '119px',
          right: '138px',
          width: '217px',
          height: '289px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '119px',
          right: '138px',
          width: '217px',
          height: '289px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      >
        <img src={persona2} draggable={false} alt="" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '428px',
          right: '138px',
          width: '335px',
          height: '335px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      >
        <img src={persona3} draggable={false} alt="" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '783px',
          right: '256px',
          width: '217px',
          height: '289px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      >
        <img src={persona4} draggable={false} alt="" />
      </Box>

      {/* second column end */}

      {/* third column start */}

      <Box
        sx={{
          position: 'absolute',
          top: '-69px',
          right: '-99px',
          width: '217px',
          height: '289px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '240px',
          right: '-99px',
          width: '217px',
          height: '289px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      >
        <img src={persona5} draggable={false} alt="" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '549px',
          right: '-99px',
          width: '217px',
          height: '218px',
          borderRadius: '40px',
          background: (theme) => theme.palette.secondary.light,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '787px',
          right: '-98px',
          width: '334px',
          height: '336px',
          borderRadius: '1000px',
          background: (theme) => theme.palette.component.lightBluePlaceholder,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '1139px',
          right: '54px',
          width: '166px',
          height: '166px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '1325px',
          right: '54px',
          width: '166px',
          height: '118px',
          borderRadius: '40px',
          background: (theme) => theme.palette.component.lightBluePlaceholder,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '1463px',
          right: '54px',
          width: '293px',
          height: '218px',
          borderRadius: '40px',
          background: (theme) => theme.palette.info.light,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '1139px',
          right: '-105px',
          width: '139px',
          height: '541px',
          borderRadius: '40px',
          background: (theme) => theme.palette.secondary.light,
        }}
      />

      {/* third column end */}
    </Box>
  );
};
