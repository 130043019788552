import { NavLink, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import {
  Header,
  SidebarWrapper,
  UserNameWithAvatar,
  BasicLinksList,
  SidebarToggle,
  SidebarMobileDrawer,
  SidebarButton,
  StyledPopover,
  StyledPopoverOption,
  SidebarLinkButton,
} from './components';
import { useScreenWidth } from 'hooks';
import { useEffect, useState } from 'react';
import { paths } from 'routing';
import { ChevronUp, ChevronDown, SignOut } from 'assets/icons';
import { useAuthContext } from 'contexts';

export const DashboardLayout = () => {
  const { isSm } = useScreenWidth();
  const { signOut } = useAuthContext();

  const [isMobileSidebarOpened, setMobileSidebarOpened] = useState(false);
  const [isDesktopUserPopoverOpened, setIsDesktopUserPopoverOpened] =
    useState<Element | null>(null);

  const onMobileSidebarClose = () => {
    setMobileSidebarOpened(false);
  };

  useEffect(() => {
    if (!isSm && isMobileSidebarOpened) onMobileSidebarClose();
    if (isSm && isDesktopUserPopoverOpened) setIsDesktopUserPopoverOpened(null);
  }, [isSm, isMobileSidebarOpened, isDesktopUserPopoverOpened]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Header
        rightSlot={
          isSm ? (
            <SidebarToggle
              isOpen={isMobileSidebarOpened}
              toggle={() => setMobileSidebarOpened(!isMobileSidebarOpened)}
            />
          ) : (
            <Box
              onClick={(e) => setIsDesktopUserPopoverOpened(e.currentTarget)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                svg: {
                  marginLeft: 8,
                  path: { stroke: 'white' },
                },
              }}
            >
              <UserNameWithAvatar />
              {isDesktopUserPopoverOpened ? <ChevronUp /> : <ChevronDown />}
            </Box>
          )
        }
      />

      <StyledPopover
        open={!!isDesktopUserPopoverOpened}
        anchorEl={isDesktopUserPopoverOpened}
        onClose={() => setIsDesktopUserPopoverOpened(null)}
      >
        <NavLink to={paths.myProfile()}>
          <StyledPopoverOption
            onClick={() => setIsDesktopUserPopoverOpened(null)}
          >
            Profile
          </StyledPopoverOption>
        </NavLink>
        <StyledPopoverOption
          color="error"
          onClick={() => {
            setIsDesktopUserPopoverOpened(null);
            signOut();
          }}
        >
          Logout
        </StyledPopoverOption>
      </StyledPopover>

      <SidebarMobileDrawer
        isMobileSidebarOpened={isMobileSidebarOpened && isSm}
        onClose={onMobileSidebarClose}
      >
        <BasicLinksList onLinkClick={onMobileSidebarClose} />

        <Box flex={1} />

        <SidebarLinkButton
          route={paths.myProfile()}
          onClick={onMobileSidebarClose}
        >
          <UserNameWithAvatar dark />
        </SidebarLinkButton>

        <SidebarButton
          onClick={() => {
            onMobileSidebarClose();
            signOut();
          }}
          icon={<SignOut />}
          noBottomSeparator
          noSpacingBottom
        >
          Logout
        </SidebarButton>
      </SidebarMobileDrawer>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          minWidth: 0,
          minHeight: 0,
        }}
      >
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
            width: '325px',
          })}
        >
          <SidebarWrapper>
            <BasicLinksList onLinkClick={onMobileSidebarClose} />
          </SidebarWrapper>
        </Box>

        <Box
          sx={(theme) => ({
            flex: 1,
            overflow: 'auto',
            padding: '44px 80px',

            [theme.breakpoints.down('lg')]: {
              padding: '40px',
            },

            [theme.breakpoints.down('sm')]: {
              padding: '20px',
            },
          })}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
