import { ReactNode } from 'react';
import type { SxProps, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';

export const Tag = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Typography
      color="grey.900"
      variant="subtitle2"
      lineHeight="24px"
      fontWeight={400}
      sx={[
        {
          background: (theme) => theme.palette.info.main,
          borderRadius: '1000px',
          px: '8px',
          userSelect: 'none',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  );
};
