import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ContentCardWrapper } from 'components';
import { Tag } from './Tag';

type CourseInfoBoxProps = {
  href: string;
  title: string;
  duration: string;
  format: string;
  idealFor: string[];
  onClick?: () => void;
};

export const CourseInfoBox = ({
  href,
  title,
  duration,
  format,
  idealFor,
  onClick,
}: CourseInfoBoxProps) => {
  return (
    <ContentCardWrapper>
      <Link
        onClick={onClick}
        href={href}
        underline="none"
        target="_blank"
        sx={(theme) => ({
          height: '100%',
          borderRadius: '16px',
          padding: '16px 24px',
          border: '1px solid',
          borderColor: theme.palette.component.border,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',

          '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.light,
            boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.2)',
            transform: 'scale(1.01,1.01)',
            transition: 'all 0.3s',
          },
        })}
      >
        <Typography
          variant="textLarge"
          fontWeight={700}
          color="text.regular"
          sx={{ marginBottom: 8 }}
        >
          {title}
        </Typography>
        <Typography variant="textSmall" color="text.secondary">
          Duration: {duration}
        </Typography>
        <Typography
          variant="textSmall"
          color="text.secondary"
          sx={{ marginBottom: 16 }}
        >
          Format: {format}
        </Typography>
        <Button
          variant="contained"
          size="small"
          sx={{ marginBottom: 8, px: 9 }}
        >
          <Typography variant="caption" fontWeight={700}>
            View This Course
          </Typography>
        </Button>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          fontWeight={400}
          sx={{ display: 'flex', flexWrap: 'wrap' }}
        >
          Ideal for:&nbsp;
          {idealFor.map((category) => (
            <Tag key={category}>{category}</Tag>
          ))}
        </Typography>
      </Link>
    </ContentCardWrapper>
  );
};
