import { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledNavigationArrow } from './components';
import { NavigationArrowLeft, NavigationArrowRight } from 'assets/icons';
import Button from '@mui/material/Button';

interface StepperProps {
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  goNext: () => void;
  goPrev: () => void;
  onFinish: () => void;
  currentStepIndex: number;
  steps: ReactNode[];
}

export const Stepper = ({
  steps,
  goNext,
  goPrev,
  onFinish,
  prevDisabled,
  nextDisabled,
  currentStepIndex,
}: StepperProps) => {
  const isLastStep = steps.length - 1 === currentStepIndex;

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
          paddingX: '0',
        },
      })}
    >
      <Box
        sx={{
          backgroundColor: 'secondary.light',
          padding: '4px 16px',
          borderRadius: '1000px',
          maxWidth: 'max-content',
          marginX: 'auto',
          marginBottom: 4,
        }}
      >
        <Typography variant="textSmall" fontWeight={400}>
          {currentStepIndex + 1}/{steps.length}
        </Typography>
      </Box>

      {!prevDisabled && (
        <StyledNavigationArrow
          onClick={goPrev}
          // @ts-ignore
          sx={(theme) => ({
            position: 'absolute',
            left: '20px',
            top: '50%',
            [theme.breakpoints.down('sm')]: {
              left: '10px',
            },
          })}
        >
          <NavigationArrowLeft />
        </StyledNavigationArrow>
      )}

      {steps[currentStepIndex]}

      <Button
        key={currentStepIndex}
        disabled={nextDisabled && !isLastStep}
        sx={{ marginTop: '50px', mx: 'auto' }}
        variant="contained"
        onClick={() => {
          if (!isLastStep) {
            !nextDisabled && goNext();
          } else {
            onFinish();
          }
        }}
      >
        {!isLastStep ? 'Next' : 'Take me to the GE-Pass home page'}
      </Button>

      {!nextDisabled && (
        <StyledNavigationArrow
          onClick={goNext}
          // @ts-ignore
          sx={(theme) => ({
            position: 'absolute',
            right: '20px',
            top: '50%',

            [theme.breakpoints.down('sm')]: {
              right: '10px',
            },
          })}
        >
          <NavigationArrowRight />
        </StyledNavigationArrow>
      )}
    </Box>
  );
};
