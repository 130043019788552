import { useEffect, useState } from 'react';
import { check_employee_user_name, UserNameAvailability } from 'api';
import { useUserProfileContext } from 'contexts';
import { isUserNameValid } from './isUserNameValid';
import { amplitudeService } from 'services';

export const useProfileQuizState = () => {
  const { createMyUserProfile, userEmail } = useUserProfileContext();

  const [userNameError, setUserNameError] = useState('');
  const [isUserUniqueCheckLoading, setIsUserUniqueCheckLoading] =
    useState(false);

  const [userName, setUserName] = useState('');
  // explicit_no_country_code

  const [userCountryCode, setUserCountryCode] = useState('');
  const [explicitNoCountryCode, setExplicitNoCountryCode] = useState(false);
  const [remoteWorkInterest, setRemoteWorkInterest] = useState('');
  const [seniorityLevel, setSeniorityLevel] = useState('');
  const [remoteWorkExperience, setRemoteWorkExperience] = useState('');
  const [primarySkillSet, setPrimarySkillSet] = useState('');

  const validateUserName = () => {
    setUserNameError('');
    if (!userName) return setUserNameError('Username should not be empty');

    const userNameFormattingIsCorrect = isUserNameValid(userName);

    if (!userNameFormattingIsCorrect) {
      return setUserNameError(
        'Use numbers, letters, _ and - e.g.: john_doe123'
      );
    }

    setUserNameError('');
  };

  const checkUserNameIsNotAlreadyTaken = async () => {
    setIsUserUniqueCheckLoading(true);
    const { data } = await check_employee_user_name(userName);
    setIsUserUniqueCheckLoading(false);
    const isUnique = data === UserNameAvailability.VALID;

    if (!isUnique) {
      setUserNameError(
        'This username is already taken. Please choose another one.'
      );
    }

    return isUnique;
  };

  const isStepperValid = !!(
    remoteWorkInterest &&
    seniorityLevel &&
    remoteWorkExperience &&
    primarySkillSet &&
    userCountryCode &&
    !userNameError &&
    !isUserUniqueCheckLoading
  );

  useEffect(() => {
    validateUserName();
  }, [userName]);

  const onFinish = () => {
    if (!isStepperValid) return;

    createMyUserProfile({
      remote_work_interest: remoteWorkInterest,
      primary_skill_set: primarySkillSet,
      seniority_level: seniorityLevel,
      remote_work_experience: remoteWorkExperience,
      user_country_code: userCountryCode,
      explicit_no_country_code: explicitNoCountryCode,
      user_name: userName,
    });

    userEmail && amplitudeService.logOnboardingCompleted(userEmail);
  };

  return {
    userName,
    userCountryCode,
    explicitNoCountryCode,
    isUserUniqueCheckLoading,
    userNameError,
    remoteWorkInterest,
    seniorityLevel,
    remoteWorkExperience,
    primarySkillSet,
    validateUserName,
    setUserName,
    setUserCountryCode,
    setExplicitNoCountryCode,
    setRemoteWorkInterest,
    setSeniorityLevel,
    setRemoteWorkExperience,
    setPrimarySkillSet,
    checkUserNameIsNotAlreadyTaken,
    onFinish,
  };
};
