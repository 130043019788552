import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export const FullHeightLoadingOverlay = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: (theme) => theme.palette.info.light,
      }}
    >
      <CircularProgress
        sx={{
          marginBottom: '16px',
        }}
        size={60}
      />
      <Typography variant="textMedium" fontWeight={700}>
        Preparing your GE-Pass...
      </Typography>
    </Box>
  );
};
