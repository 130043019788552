import { QuizStep } from '../components';
import { CheckboxWithLabel, CountriesAutocomplete } from 'components';
import Box from '@mui/material/Box';

interface PickUsernameStepProps {
  explicitNoCountryCode: boolean;
  setExplicitNoCountryCode: (val: boolean) => void;
  userCountryCode: string;
  setUserCountryCode: (val: string) => void;
}

export const LocationStep = ({
  explicitNoCountryCode,
  setExplicitNoCountryCode,
  userCountryCode,
  setUserCountryCode,
}: PickUsernameStepProps) => {
  return (
    <QuizStep title="Please select your location">
      <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column' }}>
        <CountriesAutocomplete
          selectedCountry={userCountryCode}
          changeSelectedCountry={(val) => setUserCountryCode(val ?? '')}
        />

        <CheckboxWithLabel
          value={explicitNoCountryCode}
          onChange={() => {
            setExplicitNoCountryCode(!explicitNoCountryCode);
          }}
          label="I’m also a digital nomad"
        />
      </Box>
    </QuizStep>
  );
};
