import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';

export const StyledNavigationArrowLeft = styled(IconButton)(({ theme }) => ({
  display: 'none',
  border: '1px solid',
  borderColor: theme.palette.component.border,
  padding: '20px',
  position: 'absolute',
  top: '50%',
  left: '10%',
  background: 'white',

  svg: {
    width: '26px',
    height: '26px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '10px',
    left: '4px',

    svg: {
      width: '13px',
      height: '13px',
    },
  },
}));

export const StyledNavigationArrowRight = styled(IconButton)(({ theme }) => ({
  display: 'none',
  border: '1px solid',
  borderColor: theme.palette.component.border,
  padding: '20px',
  position: 'absolute',
  top: '50%',
  right: '10%',
  background: 'white',

  svg: {
    width: '26px',
    height: '26px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '10px',
    right: '4px',

    svg: {
      width: '13px',
      height: '13px',
    },
  },
}));

export const StyledNavigationArrow = styled(IconButton)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.component.border,
  padding: '20px',
  background: 'white',
  zIndex: 1,

  svg: {
    width: '26px',
    height: '26px',
  },

  '&:hover': {
    background: 'white',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '10px',

    svg: {
      width: '13px',
      height: '13px',
    },
  },
}));
