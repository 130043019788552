import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { ReactNode } from 'react';

type ContentSectionProps = {
  title: string;
  children: ReactNode;
};

export const ContentSection = ({ title, children }: ContentSectionProps) => {
  return (
    <Box
      sx={{
        mb: 24,
        pb: 12,
        borderBottom: (theme) => `1px solid ${theme.palette.component.border}`,
      }}
    >
      <Typography variant="textLarge" fontWeight={700}>
        {title}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          mt: 15,

          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
          },
        })}
      >
        {children}
      </Box>
    </Box>
  );
};
