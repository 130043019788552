export const isUserNameValid = (userName: string) => {
  /*
    Usernames can only have:
    - Lowercase Letters (a-z)
    - Numbers (0-9)
    - Minus (-)
    - Underscores (_)
  */
  const res = /^[a-zA-Z0-9_-]+$/.exec(userName);
  return !!res;
};
