import { Navigate, Route, Routes } from 'react-router-dom';
import {
  Community,
  Courses,
  Home,
  Jobs,
  Login,
  MyProfile,
  ProfileQuiz,
  PublicUser,
} from 'pages';
import { useAuthContext, useUserProfileContext } from 'contexts';
import { DashboardLayout } from 'layouts';
import { FullHeightLoadingOverlay } from 'components';
import { paths } from './routerPaths';
import { QuizLayout } from 'layouts/QuizLayout';

// todo auth object
// todo research postgresql views
// todo think about handling supabase responses (err data)
// todo think about loading wrappers/hooks

export const RouterConfig = () => {
  const { user, userLoadingState } = useAuthContext();
  const { userProfile, userProfileLoadingState } = useUserProfileContext();

  const hasUser = !!user;
  const hasProfile = !!userProfile;

  if (
    // we don't know if user is logged in so lets wait
    userLoadingState !== 'finished' ||
    // or user is logged in but we don't know if user profile is present so lets wait
    (hasUser && userProfileLoadingState !== 'finished')
  ) {
    return <FullHeightLoadingOverlay />;
  }

  // non-authenticated routes should be added here
  if (!hasUser) {
    return (
      <Routes>
        <Route path={paths.login()} element={<Login />} />
        <Route
          path="/user/:username"
          element={<PublicUser redirectIfUserNotFound={paths.login()} />}
        />

        <Route path="*" element={<Navigate replace to={paths.login()} />} />
      </Routes>
    );
  }

  // if user does not have profile but already is logged in we want force them filling this qiuz
  if (hasUser && !hasProfile) {
    return (
      <Routes>
        <Route element={<QuizLayout />}>
          <Route path={paths.profileQuiz()} element={<ProfileQuiz />} />
        </Route>

        <Route
          path="/user/:username"
          element={<PublicUser redirectIfUserNotFound={paths.profileQuiz()} />}
        />
        <Route
          path="*"
          element={<Navigate replace to={paths.profileQuiz()} />}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* this is layout route*/}
      <Route element={<DashboardLayout />}>
        <Route path={paths.home()} element={<Home />} />
        <Route path={paths.myProfile()} element={<MyProfile />} />
        <Route path={paths.jobs()} element={<Jobs />} />
        <Route path={paths.courses()} element={<Courses />} />
        <Route path={paths.community()} element={<Community />} />
      </Route>

      <Route
        path="/user/:username"
        element={<PublicUser redirectIfUserNotFound={paths.home()} />}
      />

      <Route path="*" element={<Navigate replace to={paths.home()} />} />
    </Routes>
  );
};
