import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import avatars from 'assets/images/avatars.png';
import { CheckboxWithLabel, RowWrapper } from 'components';
import { get_employee_user_profiles_count } from 'api/rpc';
import { LoginButton } from './LoginButton';
import { TestimonialData, testimonialsService } from 'services';
import { CustomCarousel } from './Carousel';
import { Testimonial } from './Testimonial';
import { SideIllustration } from './SideIllustration';

export const Login = () => {
  const [usersLength, setUsersLength] = useState<number | null>(null);
  const [testimonials, setTestimonials] = useState<TestimonialData[]>([]);
  const [isAgreeToTerms, setIsAgreeToTerms] = useState<boolean>(true);

  const fetchTestimonials = async () => {
    const response = await testimonialsService.getTestimonials();
    setTestimonials(response);
  };

  const fetchUsersLength = async () => {
    const { data } = await get_employee_user_profiles_count();
    setUsersLength(data ?? null);
  };

  useEffect(() => {
    fetchUsersLength();
    fetchTestimonials();
  }, []);

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          padding: '24px 20px',

          marginTop: '16px',
          flex: 1,
          minWidth: 0,
        })}
      >
        <Box
          sx={(theme) => ({
            margin: '0 auto',
            maxWidth: '80%',

            [theme.breakpoints.down('xl')]: {
              maxWidth: '90%',
            },

            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          })}
        >
          <Typography
            variant="textSmall"
            lineHeight="32px"
            fontWeight={700}
            sx={(theme) => ({
              marginBottom: '90px',
              display: 'block',

              [theme.breakpoints.down('lg')]: {
                marginBottom: '40px',
              },
            })}
          >
            GE-Pass
          </Typography>

          <Typography
            variant="h3"
            alignSelf="stretch"
            fontFamily="Caudex"
            mb="24px"
          >
            Find life changing global work opportunties
          </Typography>
          <RowWrapper>
            <Box padding="4px 24px 4px 8px" display="flex" alignItems="center">
              <Box
                sx={{
                  height: '44px',
                }}
                component="img"
                src={avatars}
                alt="avatars"
                mr={4}
                draggable={false}
              />
              <Box display="flex">
                <Typography variant="textSmall" fontWeight={400}>
                  <Typography
                    component="span"
                    variant="textSmall"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {usersLength ?? null} people
                  </Typography>
                  &nbsp;already claimed their GE-Pass
                </Typography>
              </Box>
            </Box>
          </RowWrapper>
          <Box mt={24} display="flex" alignItems="center" flexWrap="wrap">
            <LoginButton
              sx={(theme) => ({
                mb: 8,
                mr: 8,
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  mr: 0,
                },
              })}
              disabled={!isAgreeToTerms}
            />
            <Typography
              variant="subtitle1"
              fontWeight={400}
              sx={(theme) => ({
                mb: 8,
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  textAlign: 'center',
                },
              })}
            >
              pass.oysterhr.com/user/your-name
            </Typography>
          </Box>

          <CheckboxWithLabel
            sx={{ mt: '24px', mb: 40 }}
            value={isAgreeToTerms}
            onChange={() => setIsAgreeToTerms(!isAgreeToTerms)}
            label={
              <Box>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  fontWeight={400}
                >
                  By creating an account, you agree to our{' '}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    color="text.secondary"
                    fontWeight={400}
                    sx={{ textDecoration: 'underline' }}
                  >
                    <a
                      href="https://app.oysterhr.com/term_of_use"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of service
                    </a>
                  </Typography>{' '}
                  and{' '}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    color="text.secondary"
                    fontWeight={400}
                    sx={{ textDecoration: 'underline' }}
                  >
                    <a
                      href="https://app.oysterhr.com/privacy_policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </Typography>
                </Typography>
              </Box>
            }
          />
          {testimonials && (
            <Box
              sx={(theme) => ({
                marginBottom: '20px',
                maxWidth: '1200px',
              })}
            >
              <CustomCarousel>
                {testimonials.map((testimonial: TestimonialData) => (
                  <Testimonial
                    key={testimonial.id}
                    authorName={testimonial.Name}
                    text={testimonial.text}
                    authorPhoto={testimonial.user_photo}
                    authorPosition={testimonial.user_job_position}
                  />
                ))}
              </CustomCarousel>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100%',
          width: '591px',
          borderRadius: '40px',
          flexShrink: 0,
          position: 'sticky',
          top: 0,
          left: 0,

          [theme.breakpoints.down('lg')]: {
            width: '450px',
          },

          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <SideIllustration />
      </Box>
    </Box>
  );
};
