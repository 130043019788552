import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface TestimonialProps {
  text: string;
  authorPhoto: string;
  authorName: string;
  authorPosition: string;
}

export const Testimonial = ({
  text,
  authorPhoto,
  authorName,
  authorPosition,
}: TestimonialProps) => {
  return (
    <Box
      padding={12}
      borderRadius={6}
      display="flex"
      flexDirection="column"
      bgcolor={(theme) => theme.palette.info.main}
    >
      <Typography variant="caption" fontWeight={400} textAlign="left">
        {text}
      </Typography>
      <Box display="flex" alignItems="center" mt={16}>
        <Box
          component="img"
          sx={{
            maxHeight: '64px',
            maxWidth: '64px',
            borderRadius: '100%',
            objectFit: 'cover',
          }}
          src={authorPhoto}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={4}
        >
          <Typography variant="subtitle1">{authorName}</Typography>
          <Typography
            variant="subtitle2"
            fontWeight={400}
            color="text.secondary"
          >
            {authorPosition}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
