import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { QuizStep } from '../components';

export const FinalStep = () => {
  return (
    <QuizStep title="You are all set!">
      <Box />
    </QuizStep>
  );
};
