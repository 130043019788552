import { Drawer, SxProps, Theme } from '@mui/material';
import { SidebarWrapper } from './SidebarWrapper';
import { ReactNode } from 'react';

interface SidebarMobileDrawerProps {
  headerHeight?: string;
  fullHeight?: boolean;
  isMobileSidebarOpened: boolean;
  onClose: () => void;
  children: ReactNode;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  sx?: SxProps<Theme>;
}

export const SidebarMobileDrawer = ({
  fullHeight = true,
  anchor = 'right',

  headerHeight = '60px',
  isMobileSidebarOpened,
  onClose,
  children,
  sx,
}: SidebarMobileDrawerProps) => {
  return (
    <Drawer
      sx={[
        {
          '&, .MuiPaper-root, .MuiBackdrop-root': {
            maxHeight: `calc(100% - ${headerHeight})`,
            top: headerHeight,
          },

          '.MuiPaper-root': {
            borderRadius: 0,
            width: '100%',
            boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.2)',
            height: fullHeight ? '100%' : 'auto',
          },

          '.MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      open={isMobileSidebarOpened}
      onClose={onClose}
      anchor={anchor}
    >
      <SidebarWrapper noBorders>{children}</SidebarWrapper>
    </Drawer>
  );
};
