import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { countries } from 'api';
import { ChevronDown } from 'assets/icons';
import { FieldBlockWrapper } from 'components';

interface CountriesAutocompleteProps {
  selectedCountry: string | null;
  changeSelectedCountry: (newValue: string | null) => void;
  disabled?: boolean;
}

export const CountriesAutocomplete = ({
  selectedCountry,
  changeSelectedCountry,
  disabled,
}: CountriesAutocompleteProps) => {
  const currentOption =
    countries.find((option) => option.code === selectedCountry) ?? null;

  return (
    <FieldBlockWrapper title="Country">
      <Autocomplete
        value={currentOption}
        onChange={(event, newValue) => {
          changeSelectedCountry(newValue?.code ?? null);
        }}
        disableClearable={!!currentOption}
        options={countries}
        getOptionLabel={(option) => option.name ?? ''}
        isOptionEqualToValue={(option, value) => {
          return option.code === value.code;
        }}
        popupIcon={<ChevronDown />}
        fullWidth
        disabled={disabled}
        filterOptions={(options, { inputValue }) =>
          options.filter(({ name, code, searchHelper }) =>
            inputValue
              .trim()
              .toLowerCase()
              .split(' ')
              .every((word) =>
                `${name} ${code} ${searchHelper}`
                  .trim()
                  .toLowerCase()
                  .includes(word)
              )
          )
        }
        renderInput={(params) => (
          <TextField {...params} placeholder="E.g. United States" />
        )}
      />
    </FieldBlockWrapper>
  );
};
