import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { ChevronDown } from 'assets/icons';
import { FieldBlockWrapper } from 'components';

type CustomAutocompleteProps = {
  title: string;
  options: readonly string[];
  value: string | null;
  setValue: (newValue: string | null) => void;
};

export const CustomAutocomplete = ({
  title,
  options,
  value,
  setValue,
}: CustomAutocompleteProps) => {
  return (
    <FieldBlockWrapper title={title}>
      <Autocomplete
        value={value ?? null}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        options={options}
        fullWidth
        popupIcon={<ChevronDown />}
        disableClearable={!!value}
        renderInput={(params) => (
          <TextField {...params} placeholder="Pick an option" />
        )}
      />
    </FieldBlockWrapper>
  );
};
