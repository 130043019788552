import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { courses } from 'api';
import { CompleteProfilePrompt } from 'components';
import { CourseInfoBox } from './components';
import { amplitudeService } from 'services';
import { useUserProfileContext } from 'contexts';

export const Courses = () => {
  const { userEmail } = useUserProfileContext();

  useEffect(() => {
    !!userEmail && amplitudeService.logCoursesVisited(userEmail);
  }, []);

  return (
    <Box>
      <Typography
        variant="h5"
        fontFamily="Caudex"
        sx={(theme) => ({
          marginBottom: 12,

          [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
          },
        })}
      >
        Free courses
      </Typography>
      <CompleteProfilePrompt />

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          mt: 25,

          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
          },
        })}
      >
        {courses.map((course) => (
          <CourseInfoBox
            onClick={() => {
              userEmail &&
                amplitudeService.logCourseClicked(userEmail, course.href);
            }}
            key={course.href}
            href={course.href}
            title={course.title}
            duration={course.duration}
            format={course.format}
            idealFor={course.idealFor}
          />
        ))}
      </Box>
    </Box>
  );
};
