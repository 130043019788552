import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type QuizStepProps = {
  title: string;
  children: ReactNode;
};

export const QuizStep = ({ title, children }: QuizStepProps) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 'max-content',
        }}
      >
        <Typography variant="h4" mb={16} fontFamily="Caudex" textAlign="center">
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
