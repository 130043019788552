import type { SVGProps } from 'react';

export const CheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 4 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="4.5"
      width="15"
      height="15"
      rx="3.5"
      fill="#3668FF"
      stroke="#BDBDBD"
    />
    <path
      d="M5 12.5L7.5 15L12.5 10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
