import type { MouseEventHandler } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Cancel } from 'assets/icons';

import type { SxProps } from '@mui/material';

export interface ClearButtonProps {
  title: string;
  handleClear: MouseEventHandler;
  sx?: SxProps;
}

export const ClearButton = ({ title, sx, handleClear }: ClearButtonProps) => {
  return (
    <Button
      onClick={handleClear}
      sx={[
        {
          height: 'min-content',
          padding: '2px 4px',
          backgroundColor: 'grey.200',
          borderRadius: '2px',

          ':hover': {
            backgroundColor: 'grey.300',
          },

          svg: {
            height: '22px',
            width: '22px',
            path: { stroke: 'black' },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography fontSize="12px" lineHeight="14px" marginRight={1}>
        {title}
      </Typography>
      <Cancel />
    </Button>
  );
};
