import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { seniority_level } from 'api';
import { QuizStep, StyledFormControlLabel } from '../components';
import { CheckboxIdle, CheckboxChecked } from 'assets/icons';
import { StepProps } from '../types';

export const SeniorityLevelStep = ({ value, setValue }: StepProps) => {
  return (
    <QuizStep title="What level of experience do you have in your role?">
      <FormControl sx={{ width: '80%' }}>
        <RadioGroup value={value} onChange={(e) => setValue(e.target.value)}>
          {seniority_level.map((option: string) => (
            <StyledFormControlLabel
              key={option}
              control={
                <Radio
                  icon={<CheckboxIdle />}
                  checkedIcon={<CheckboxChecked />}
                />
              }
              label={option}
              value={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </QuizStep>
  );
};
