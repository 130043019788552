import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { seniority_level } from 'api';
import { useUserProfileContext } from 'contexts';
import {
  CustomAutocomplete,
  CountriesAutocomplete,
  ExpectedSalaryInput,
  CheckboxWithLabel,
  LocationsMultiselect,
} from 'components';
import { amplitudeService } from 'services';

const locations = [
  { code: 'Worldwide', label: 'Worldwide' },
  { code: 'Americas', label: 'Americas' },
  { code: 'LATAM', label: 'LATAM (Latin America)' },
  { code: 'MENA', label: 'MENA (Middle East & North Africa)' },
  { code: 'US', label: 'US' },
  { code: 'Other', label: 'Other' },
];

// todo extract into a hook
// todo investigate why we still have CustomAutocomplete
export const MyProfile = () => {
  const { userProfile, updateMyUserProfile, userEmail } =
    useUserProfileContext();

  const userProfileCasted = userProfile!;

  const [loading, setLoading] = useState<boolean>(false);

  const [jobTitle, setJobTitle] = useState<string | null>(
    userProfileCasted.user_job_title ?? null
  );

  const [seniorityLevel, setSeniorityLevel] = useState<string | null>(
    userProfileCasted.seniority_level ?? null
  );
  const [expectedSalaryAmount, setExpectedSalaryAmount] = useState<
    number | null
  >(userProfileCasted.expected_salary_amount ?? null);

  const [expectedSalaryCurrencyCode, setExpectedSalaryCurrencyCode] = useState<
    string | null
  >(userProfileCasted.expected_salary_currency_code ?? null);

  const [userCountryCode, setUserCountryCode] = useState<string | null>(
    userProfileCasted.user_country_code ?? null
  );

  const [explicitNoCountryCode, setExplicitNoCountryCode] = useState<boolean>(
    userProfileCasted.explicit_no_country_code ?? false
  );

  const [preferredLocations, setPreferredLocations] = useState<
    { code: string; label: string }[]
  >([]);

  const jobTitleChanged = jobTitle !== userProfileCasted.user_job_title;

  const seniorityLevelChanged =
    seniorityLevel !== userProfileCasted.seniority_level;

  const expectedSalaryAmountChanged =
    expectedSalaryAmount !== userProfileCasted.expected_salary_amount;

  const expectedSalaryCurrencyChanged =
    expectedSalaryCurrencyCode !==
    userProfileCasted.expected_salary_currency_code;

  const usersCountryChangedCode =
    userCountryCode !== userProfileCasted.user_country_code;

  const explicitNoCountryCodeChanged =
    explicitNoCountryCode !== userProfileCasted.explicit_no_country_code;

  useEffect(() => {
    !!userEmail && amplitudeService.logProfileVisited(userEmail);
  }, []);

  return (
    <>
      <Box sx={{ marginBottom: 20 }}>
        <Typography
          variant="h5"
          fontFamily="Caudex"
          sx={{
            mb: 4,
          }}
        >
          Edit your profile
        </Typography>
        <Typography variant="textSmall" fontWeight={400} color="text.secondary">
          {userProfileCasted.user_name}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: '345px' }}>
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={400}
            sx={{ marginBottom: 4 }}
          >
            What is your current job title?
          </Typography>
          <TextField
            sx={{ mb: 12 }}
            fullWidth
            placeholder="E.g. Marketing manager"
            value={jobTitle ?? ''}
            onChange={({ target: { value } }) => {
              if (value === '') return setJobTitle(null);

              setJobTitle(value);
            }}
          />
        </Box>

        <CustomAutocomplete
          title="Seniority level"
          options={seniority_level}
          value={seniorityLevel}
          setValue={setSeniorityLevel}
        />

        <CountriesAutocomplete
          selectedCountry={userCountryCode}
          changeSelectedCountry={setUserCountryCode}
        />

        <CheckboxWithLabel
          label="I’m also a digital nomad"
          value={explicitNoCountryCode}
          onChange={() => {
            setExplicitNoCountryCode(!explicitNoCountryCode);
          }}
        />

        <LocationsMultiselect
          options={locations}
          value={preferredLocations}
          setValue={setPreferredLocations}
        />

        <ExpectedSalaryInput
          expectedSalaryAmount={expectedSalaryAmount}
          setExpectedSalaryAmount={setExpectedSalaryAmount}
          expectedSalaryCurrencyCode={expectedSalaryCurrencyCode}
          setExpectedSalaryCurrencyCode={setExpectedSalaryCurrencyCode}
        />
        <Box sx={{ mt: 20 }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            sx={{ mr: 8 }}
            disabled={
              !jobTitleChanged &&
              !seniorityLevelChanged &&
              !expectedSalaryAmountChanged &&
              !expectedSalaryCurrencyChanged &&
              !explicitNoCountryCodeChanged &&
              !usersCountryChangedCode
            }
            onClick={async () => {
              if (loading) return;
              setLoading(true);
              // todo current types generating tool is marking nullable columns as undefined/not required instead of `type | null`
              // @ts-ignore
              await updateMyUserProfile({
                ...(jobTitleChanged && {
                  user_job_title: jobTitle,
                }),
                ...(seniorityLevelChanged && {
                  seniority_level: seniorityLevel,
                }),
                ...(expectedSalaryAmountChanged && {
                  expected_salary_amount: expectedSalaryAmount,
                }),
                ...(expectedSalaryCurrencyChanged && {
                  expected_salary_currency_code: expectedSalaryCurrencyCode,
                }),
                ...(usersCountryChangedCode && {
                  user_country_code: userCountryCode,
                }),
                ...(explicitNoCountryCodeChanged && {
                  explicit_no_country_code: explicitNoCountryCode,
                }),
              });
              setLoading(false);
            }}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={() => {
              setJobTitle(userProfileCasted.user_job_title ?? null);

              setSeniorityLevel(userProfileCasted.seniority_level ?? null);

              setExpectedSalaryAmount(
                userProfileCasted.expected_salary_amount ?? null
              );

              setExpectedSalaryCurrencyCode(
                userProfileCasted.expected_salary_currency_code ?? null
              );

              setUserCountryCode(userProfileCasted.user_country_code ?? null);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};
