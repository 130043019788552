import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { paths } from 'routing';

import { ReactNode } from 'react';

export const Header = ({ rightSlot }: { rightSlot?: ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 24px',
        width: '100%',
        height: '60px',
        minHeight: '60px',
        backgroundColor: 'primary.main',
        userSelect: 'none',
      }}
    >
      <NavLink
        to={paths.home()}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography variant="textSmall" fontWeight={700} color="grey.A100">
          GE-Pass
        </Typography>
      </NavLink>
      {rightSlot}
    </Box>
  );
};
