import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { remote_work_interest } from 'api';
import { QuizStep, StyledFormControlLabel } from '../components';
import { CheckboxIdle, CheckboxChecked } from 'assets/icons';
import { StepProps } from '../types';

export const RemoteWorkInterestStep = ({ value, setValue }: StepProps) => {
  return (
    <QuizStep title="Where are you in your job seeker journey?">
      <FormControl sx={{ maxWidth: '80%' }}>
        <RadioGroup value={value} onChange={(e) => setValue(e.target.value)}>
          {remote_work_interest.map((option: string) => (
            <StyledFormControlLabel
              key={option}
              control={
                <Radio
                  icon={<CheckboxIdle />}
                  checkedIcon={<CheckboxChecked />}
                />
              }
              label={option}
              value={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </QuizStep>
  );
};
