export interface Resource {
  href: string;
  mediaType: 'video' | 'audio' | 'picture' | 'iframe' | 'img';
  mediaSrc: string;
  title: string;
  description: string;
}

export const resources: Resource[] = [
  {
    href: 'https://www.oysterhr.com/library/the-7-best-remote-job-boards-for-2022',
    mediaType: 'img',
    mediaSrc:
      'https://assets-global.website-files.com/601d611d601043ab3e22931b/631b9d63ef0eb9b018869150_NqpWDM9E.jpeg',
    title: 'The 7 best remote job boards for 2022',
    description: 'Find your next opportunity with these top job boards.',
  },
  {
    href: 'https://www.oysterhr.com/library/10-practical-tips-for-working-abroad',
    mediaType: 'img',
    mediaSrc:
      'https://assets-global.website-files.com/601d611d601043ab3e22931b/631a3cf589d17100e00e5dd4_GEP-Blog-tips%20for%20working%20abroad_1200x650_01.jpg',
    title: '10 practical tips for working abroad',
    description: 'Some top ideas for working internationally.',
  },
  {
    href: 'https://www.oysterhr.com/library/how-to-get-hired-anywhere-with-oysters-global-employment-pass',
    mediaType: 'img',
    mediaSrc:
      'https://assets-global.website-files.com/601d611d601043ab3e22931b/630fdf5d68f70c2a66ca7dcf_EIoCszSo.png',
    title: 'How to get hired anywhere with Oyster’s Global Employment Pass',
    description: 'Ready to get hired anywhere?',
  },
];
