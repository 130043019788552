import { useState } from 'react';
import Box from '@mui/material/Box';

import {
  RemoteWorkInterestStep,
  PickUsernameStep,
  SeniorityLevelStep,
  RemoteWorkExperienceStep,
  PrimarySkillSetStep,
  FinalStep,
  LocationStep,
} from './steps';
import { Stepper } from './Stepper';
import { useProfileQuizState } from './useProfileQuizState';

export const ProfileQuiz = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const {
    userName,
    userCountryCode,
    explicitNoCountryCode,
    isUserUniqueCheckLoading,
    userNameError,
    remoteWorkInterest,
    seniorityLevel,
    primarySkillSet,
    remoteWorkExperience,
    setUserName,
    setUserCountryCode,
    setExplicitNoCountryCode,
    setRemoteWorkInterest,
    setSeniorityLevel,
    setRemoteWorkExperience,
    setPrimarySkillSet,
    checkUserNameIsNotAlreadyTaken,
    onFinish,
  } = useProfileQuizState();

  const goPrev = () => setCurrentStepIndex((prev) => prev - 1);
  const goNext = () => setCurrentStepIndex((prev) => prev + 1);

  const steps = [
    {
      nextEnabled: !!remoteWorkInterest,
      prevEnabled: false,
      goPrev,
      goNext,
      Element: (
        <RemoteWorkInterestStep
          value={remoteWorkInterest}
          setValue={setRemoteWorkInterest}
        />
      ),
    },
    {
      nextEnabled: !userNameError && !isUserUniqueCheckLoading,
      prevEnabled: true,
      goPrev,
      goNext: async () => {
        const result = await checkUserNameIsNotAlreadyTaken();

        if (result && !userNameError) {
          goNext();
        }
      },
      Element: (
        <PickUsernameStep
          value={userName}
          setValue={setUserName}
          userNameError={userNameError}
          isUserUniqueCheckLoading={isUserUniqueCheckLoading}
        />
      ),
    },
    {
      nextEnabled: !!userCountryCode,
      prevEnabled: true,
      goPrev,
      goNext,
      Element: (
        <LocationStep
          userCountryCode={userCountryCode}
          explicitNoCountryCode={explicitNoCountryCode}
          setExplicitNoCountryCode={setExplicitNoCountryCode}
          setUserCountryCode={setUserCountryCode}
        />
      ),
    },
    {
      nextEnabled: !!seniorityLevel,
      prevEnabled: true,
      goPrev,
      goNext,
      Element: (
        <SeniorityLevelStep
          value={seniorityLevel}
          setValue={setSeniorityLevel}
        />
      ),
    },
    {
      nextEnabled: !!remoteWorkExperience,
      prevEnabled: true,
      goPrev,
      goNext,
      Element: (
        <RemoteWorkExperienceStep
          value={remoteWorkExperience}
          setValue={setRemoteWorkExperience}
        />
      ),
    },
    {
      nextEnabled: !!primarySkillSet,
      prevEnabled: true,
      goPrev,
      goNext,
      Element: (
        <PrimarySkillSetStep
          value={primarySkillSet}
          setValue={setPrimarySkillSet}
        />
      ),
    },
    {
      nextEnabled: false,
      prevEnabled: true,
      goPrev,
      goNext,
      Element: <FinalStep />,
    },
  ];

  const currentStep = steps[currentStepIndex];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: '10px',
      }}
    >
      <Stepper
        onFinish={onFinish}
        prevDisabled={!currentStep?.prevEnabled}
        nextDisabled={!currentStep?.nextEnabled}
        currentStepIndex={currentStepIndex}
        goNext={currentStep.goNext}
        goPrev={currentStep.goPrev}
        steps={steps.map(({ Element }) => Element)}
      />
    </Box>
  );
};
