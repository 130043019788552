import Box from '@mui/material/Box';

import type { ReactNode } from 'react';

export const ContentCardWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={(theme) => ({
        width: '33.333%',
        marginBottom: 12,
        paddingRight: 5,
        cursor: 'default',
        [theme.breakpoints.down('lg')]: { width: '50%' },
        [theme.breakpoints.down('md')]: { paddingRight: 0, width: '100%' },
      })}
    >
      {children}
    </Box>
  );
};
