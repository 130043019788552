import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ClearButton } from 'components';

import type { ReactNode } from 'react';

type FieldBlockWrapperProps = {
  title: string;
  children: ReactNode;
  onClear?: (() => void) | null;
};

export const FieldBlockWrapper = ({
  title,
  children,
  onClear,
}: FieldBlockWrapperProps) => {
  return (
    <Box sx={{ mb: 12, width: '100%' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="subtitle1"
          fontWeight={400}
          sx={{ marginBottom: 4, marginRight: 2 }}
        >
          {title}
        </Typography>
        {onClear && <ClearButton title="Clear" handleClear={onClear} />}
      </Box>
      {children}
    </Box>
  );
};
