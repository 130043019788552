import { SidebarLinkButton } from './SidebarLinkButton';
import { paths } from 'routing';
import { Tag } from 'components';
import { Home, MediaVideo, Suitcase } from 'assets/icons';
import { SidebarButton } from './SidebarButton';

export const BasicLinksList = ({
  onLinkClick,
}: {
  onLinkClick: () => void;
}) => (
  <>
    <SidebarLinkButton
      noBottomSeparator
      noSpacingTop
      noSpacingBottom
      onClick={onLinkClick}
      route={paths.home()}
      end
      icon={<Home />}
    >
      Home
    </SidebarLinkButton>

    <SidebarLinkButton
      noBottomSeparator
      noSpacingBottom
      onClick={onLinkClick}
      route={paths.courses()}
      icon={<MediaVideo />}
    >
      Free Courses
    </SidebarLinkButton>

    <a href="https://connect.oysterhr.com/" target="_blank" rel="noreferrer">
      <SidebarButton icon={<Suitcase />}>Job Search</SidebarButton>
    </a>

    <SidebarButton
      icon={<Suitcase />}
      disabled
      noBottomSeparator
      noSpacingBottom
    >
      Community <Tag sx={{ marginLeft: '8px' }}>coming soon</Tag>
    </SidebarButton>
  </>
);
