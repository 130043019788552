import { IconButton, Popover } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Menu, Cancel } from 'assets/icons';

export const SidebarToggle = ({
  isOpen,
  toggle,
}: {
  isOpen: boolean;
  toggle: () => void;
}) => {
  return (
    <IconButton onClick={toggle}>{isOpen ? <Cancel /> : <Menu />}</IconButton>
  );
};
