import Box from '@mui/material/Box';

import type { ReactNode } from 'react';

export const Tag = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      width: 'max-content',
      padding: '2px 4px',
      borderRadius: '4px',
      backgroundColor: 'grey.50',
      marginRight: 3,
      marginBottom: 3,
    }}
  >
    {children}
  </Box>
);
