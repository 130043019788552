import { useAuthContext } from '../contexts';

export const Community = () => {
  const { signOut } = useAuthContext();

  return (
    <div>
      <h1>Community page</h1>
      <button onClick={signOut}>log out</button>
    </div>
  );
};
