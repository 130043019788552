import { ReactNode } from 'react';
import Box from '@mui/material/Box';

export interface SidebarOptionWrapperProps {
  children: ReactNode;
  noBottomSeparator?: boolean;
  noSpacingTop?: boolean;
  noSpacingBottom?: boolean;
}

export const SidebarOptionWrapper = ({
  children,
  noBottomSeparator,
  noSpacingTop,
  noSpacingBottom,
}: SidebarOptionWrapperProps) => {
  return (
    <Box
      sx={(theme) => ({
        textDecoration: 'none',
        borderBottom: '1px solid',
        borderColor: theme.palette.component.border,
        marginTop: '20px',
        paddingBottom: '20px',

        ...(noSpacingTop && { marginTop: 0 }),
        ...(noSpacingBottom && { paddingBottom: 0 }),
        ...(noBottomSeparator && { borderWidth: 0 }),
      })}
    >
      {children}
    </Box>
  );
};
