import {
  CustomMultiselectAutocomplete,
  SelectOption,
} from './CustomMultiselectAutocomplete';

import type { SxProps } from '@mui/material';

interface LocationsMultiselectProps {
  options: SelectOption[];
  value: SelectOption[];
  setValue: (value: SelectOption[]) => void;
  sx?: SxProps;
}

export const LocationsMultiselect = ({
  options,
  value,
  setValue,
  sx,
}: LocationsMultiselectProps) => (
  <CustomMultiselectAutocomplete
    title="Preferred job locations"
    placeholder="Choose from a list"
    options={options}
    value={value}
    setValue={setValue}
    sx={sx}
  />
);
