export const paths = {
  home: () => '/',
  login: () => '/login',
  myProfile: () => '/my-profile',
  profileQuiz: () => '/profile-quiz',
  courses: () => '/courses',
  jobs: () => '/jobs',
  community: () => '/community',
  noAccess: () => '/no-access',
};
