import { db } from '../client';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { UserNameAvailability } from '../consts';

export const check_employee_user_name = (
  user_name_candidate: string
): PromiseLike<
  PostgrestSingleResponse<
    UserNameAvailability.VALID | UserNameAvailability.ALREADY_EXISTS
  >
> => {
  return db.rpc('check_employee_user_name', { user_name_candidate }).single();
};
