// Regular colors
export const primaryMain = '#0E0D0D';
export const secondaryMain = '#0EE8A7';
export const secondaryLight = '#98F3CA';
export const infoMain = '#FDF2BF';
export const infoLight = '#F9F0DC';
export const grey50 = '#F3F3F2';
export const grey100 = '#F5F5F5';
export const grey200 = '#E3E3E3';
export const grey300 = '#A3A3A3';
export const grey400 = '#595959';
export const grey600 = '#000000';
export const grey900 = '#171717';
export const white = '#FFFFFF';
export const errorMain = '#E83C09';
// Text colors
export const regularTextColor = '#1B1B1A';
export const grayTextColor = '#595854';
export const placeholderTextColor = '#A3A3A3';
// Component-specific
export const lightBluePlaceholderColor = '#B3E0F7';
export const loginButtonColor = '#0A66C2';
export const carouselDotColor = '#CFCFCC';
export const selectedCarouselDotColor = '#0E0D0D';
export const borderColor = '#E7E7E6';

export const palette = {
  primary: {
    main: primaryMain,
  },
  secondary: {
    main: secondaryMain,
    light: secondaryLight,
  },
  info: {
    main: infoMain,
    light: infoLight,
  },
  grey: {
    50: grey50,
    100: grey100,
    200: grey200,
    300: grey300,
    400: grey400,
    600: grey600,
    900: grey900,
    A100: white,
  },
  error: {
    main: errorMain,
  },
  text: {
    regular: regularTextColor,
    secondary: grayTextColor,
    placeholder: placeholderTextColor,
  },
  component: {
    loginButton: loginButtonColor,
    carouselDot: carouselDotColor,
    selectedCarouselDot: selectedCarouselDotColor,
    lightBluePlaceholder: lightBluePlaceholderColor,
    border: borderColor,
  },
};
