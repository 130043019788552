import { useAuthContext } from '../contexts';

export const Jobs = () => {
  const { signOut } = useAuthContext();

  return (
    <div>
      <h1>Jobs page</h1>
      <button onClick={signOut}>log out</button>
    </div>
  );
};
