import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  getHiringCultureValueOptions,
  getHiringEmploymentTypeOptions,
  getHiringJobDepartmentOptions,
  getHiringLocationOptions,
  HiringCultureValueOptions,
  HiringEmploymentTypeOptions,
  HiringJobDepartmentOptions,
  HiringLocationOptions,
} from 'api';

import { useUserProfileContext } from './UserProfileContext';

type PersonalizationConfigsLoadingState = 'initial' | 'loading' | 'finished';

interface PersonalizationConfigsValue {
  personalizationConfigs: PersonalizationConfigsLoadingState;
  hiringCultureValueOptions: HiringCultureValueOptions;
  hiringEmploymentTypeOptions: HiringEmploymentTypeOptions;
  hiringJobDepartmentOptions: HiringJobDepartmentOptions;
  hiringLocationOptions: HiringLocationOptions;
}

const PersonalizationConfigsContext =
  createContext<PersonalizationConfigsValue>({
    personalizationConfigs: 'initial',
    hiringCultureValueOptions: [],
    hiringEmploymentTypeOptions: [],
    hiringJobDepartmentOptions: [],
    hiringLocationOptions: [],
  });

export const usePersonalizationConfigs = () =>
  useContext(PersonalizationConfigsContext);

export const PersonalizationConfigsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { userProfile } = useUserProfileContext();
  const [personalizationConfigs, setPersonalizationConfigs] =
    useState<PersonalizationConfigsLoadingState>('initial');

  const [hiringCultureValueOptions, setHiringCultureValueOptions] =
    useState<HiringCultureValueOptions>([]);

  const [hiringEmploymentTypeOptions, setHiringEmploymentTypeOptions] =
    useState<HiringEmploymentTypeOptions>([]);

  const [hiringJobDepartmentOptions, setHiringJobDepartmentOptions] =
    useState<HiringJobDepartmentOptions>([]);

  const [hiringLocationOptions, setHiringLocationOptions] =
    useState<HiringLocationOptions>([]);

  const fetchCompanyConfigOptions = async () => {
    setPersonalizationConfigs('loading');

    const [
      cultureValueOptionsResponse,
      employmentTypeOptionsResponse,
      jobDepartmentOptionsResponse,
      locationOptionsResponse,
    ] = await Promise.allSettled([
      getHiringCultureValueOptions(),
      getHiringEmploymentTypeOptions(),
      getHiringJobDepartmentOptions(),
      getHiringLocationOptions(),
    ]);

    cultureValueOptionsResponse.status === 'fulfilled' &&
      setHiringCultureValueOptions(
        cultureValueOptionsResponse.value.data ?? []
      );

    employmentTypeOptionsResponse.status === 'fulfilled' &&
      setHiringEmploymentTypeOptions(
        employmentTypeOptionsResponse.value.data ?? []
      );

    jobDepartmentOptionsResponse.status === 'fulfilled' &&
      setHiringJobDepartmentOptions(
        jobDepartmentOptionsResponse.value.data ?? []
      );

    locationOptionsResponse.status === 'fulfilled' &&
      setHiringLocationOptions(locationOptionsResponse.value.data ?? []);

    setPersonalizationConfigs('finished');
  };

  useEffect(() => {
    if (userProfile) {
      fetchCompanyConfigOptions();
    }
  }, [userProfile]);

  return (
    <PersonalizationConfigsContext.Provider
      value={{
        personalizationConfigs,
        hiringCultureValueOptions,
        hiringEmploymentTypeOptions,
        hiringJobDepartmentOptions,
        hiringLocationOptions,
      }}
    >
      {children}
    </PersonalizationConfigsContext.Provider>
  );
};
