import type { Theme } from '@mui/material';

import './index.css';

export const buildTypography = (theme: Theme) => {
  theme.typography.h1 = {
    fontSize: '65px',
    lineHeight: '80px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '42px',
    },
  };

  theme.typography.h2 = {
    fontSize: '58px',
    lineHeight: '72px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '42px',
    },
  };
  theme.typography.h3 = {
    fontSize: '51px',
    lineHeight: '62px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '42px',
    },
  };
  theme.typography.h4 = {
    fontSize: '46px',
    lineHeight: '56px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '42px',
    },
  };
  theme.typography.h5 = {
    fontSize: '41px',
    lineHeight: '52px',
    fontWeight: '700',

    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '42px',
    },
  };
  theme.typography.h6 = {
    fontSize: '36px',
    lineHeight: '48px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  };
  theme.typography.textLarge = {
    fontSize: '25px',
    lineHeight: '34px',
  };
  theme.typography.textMedium = {
    fontSize: '22px',
    lineHeight: '30px',
  };
  theme.typography.textSmall = {
    fontSize: '20px',
    lineHeight: '26px',
  };
  theme.typography.caption = {
    fontSize: '18px',
    lineHeight: '26px',
  };
  theme.typography.subtitle1 = {
    fontSize: '16px',
    lineHeight: '24px',
  };
  theme.typography.subtitle2 = {
    fontSize: '14px',
    lineHeight: '22px',
  };
};
