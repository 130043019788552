import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ThemeWrapperWithCssReset } from 'ui-kit';

import {
  AuthContextProvider,
  UserProfileContextProvider,
  PersonalizationConfigsContextProvider,
} from 'contexts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <ThemeWrapperWithCssReset>
      <AuthContextProvider>
        <UserProfileContextProvider>
          <PersonalizationConfigsContextProvider>
            <App />
          </PersonalizationConfigsContextProvider>
        </UserProfileContextProvider>
      </AuthContextProvider>
    </ThemeWrapperWithCssReset>
  </Router>
);
