export const primary_skill_set = Object.freeze([
  'Software Engineering',
  'Product Management',
  'Data',
  'Design',
  'Operations',
  'Sales and Account Management',
  'Marketing',
  'Product Marketing',
  'People Ops (HR)',
  'Finance',
  'Legal',
]);
