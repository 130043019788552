export interface Event {
  href: string;
  mediaType: 'video' | 'audio' | 'picture' | 'iframe' | 'img';
  mediaSrc: string;
  title: string;
  description: string;
}

export const events: Event[] = [
  {
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSeutYvQQasZTgRcWQ7_OYjcr3RHqNUihPBBExF8RjTXczh_cw/viewform',
    mediaType: 'img',
    mediaSrc:
      'https://lh3.googleusercontent.com/z1gJpNVW0Oif2Tx-AKdmk0YsduS3qkWmBdt7nerYcXHss__P-a7X18Pgx8M90Ov-8Vmj1ETIzOj2yhD9PyI82IY2_sg0YE9H5KoDMqrFCXrXaaNmoAMxmxhLehOUhMdT6w=w2880',
    title: 'Work From Anywhere (WFA) Week',
    description:
      'Sign up to sharpen your skills for distributed work by participating in curated hands-on workshops, create connections with other aspiring WFA talent, and be the first to receive invitations to future Oyster events for global job seekers.',
  },
];
